<template>
  <v-container class="fill-height">
    <v-navigation-drawer v-model="drawer" :permanent="display.mdAndUp.value">
      <v-list nav v-if="loading">
        <v-skeleton-loader type="avatar, text"></v-skeleton-loader>
      </v-list>
      <v-list nav v-if="!loading">
        <v-row justify="center" v-if="curso.permiso_edicion">
          <v-col cols="12">
            <v-btn-toggle class="d-flex flex-row" mandatory v-model="selectedOption">
              <v-btn class="flex-grow-1" v-for="option in options" :key="option.value" :value="option.value">
                <v-icon v-if="option.icon">{{ option.icon }}</v-icon>
                {{ option.label }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-list-item @click="selectModulo(null, null)">
          <template v-slot:prepend>
            <v-avatar class="elevation-1" size="x-large">
              <img :src="curso.imagen_url" style="object-fit: cover; width: 100%; height: 100%;">
            </v-avatar>
          </template>
          <v-list-item-title>{{ curso.titulo }}</v-list-item-title>
          <template v-if="selectedOption === 'editar'" v-slot:append>
            <v-btn color="primary" icon="mdi-cog" variant="text" @click="openEditDialog"></v-btn>
          </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(modulo, moduloIndex) in moduls.modulos" :key="modulo.id">
          <v-list-item @click="selectModulo(modulo, moduloIndex)" base-color="primary" :value="modulo">{{ modulo.titulo
            }}
          </v-list-item>
          <v-list-item v-for="(leccion, leccionIndex) in modulo.lecciones" :key="leccion.id"
            @click="selectLeccion(leccion, moduloIndex, leccionIndex)" :value="leccion">
            <v-list-item-title>{{ leccion.titulo }}</v-list-item-title>
            <template v-if="leccion.cuestionario" v-slot:append>
              <CuestionarioCurso :navbarra="true" :IDcuestionario="leccion.cuestionario"
                :nota_usuario="leccion.nota_usuario" :IDleccion="leccion.id" :selectedOption="selectedOption"
                :selectedOptionVista="selectedOptionVista" :cursoId="cursoId" @respuesta-enviada="fetchModuls(false)"
                @nuevo-cuestionario="handleNuevoCuestionario" @eliminar-cuestionario="handleEliminarCuestionario">
              </CuestionarioCurso>
            </template>
          </v-list-item>
          <v-list-item v-if="selectedOption === 'editar'" @click="() => openAddDialog('lecciones', modulo.id)">
            <v-list-item-title>{{ t('Añadir') }} {{ t('lección') }}</v-list-item-title>
            <template v-slot:append>
              <v-icon color="primary" icon="mdi-plus"></v-icon>
            </template>
          </v-list-item>
        </v-list-item>
        <v-list-item v-if="selectedOption === 'editar'" @click="() => openAddDialog('modulos')">
          <v-list-item-title>{{ t('Añadir') }} {{ t('módulo') }}</v-list-item-title>
          <template v-slot:append>
            <v-icon color="secondary" icon="mdi-plus"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-row v-if="loading">
      <v-skeleton-loader type="image, article"></v-skeleton-loader>
    </v-row>
    <v-row v-if="!loading">
      <!-- Prova nou modul -->
      <ModuloLeccion v-if="!leccionActual" :curso="curso" :moduls="moduls" :moduloIndex="moduloIndex"
        :selectedOption="selectedOption" :drawer="drawer" @update:drawer="drawer = $event"
        @update-modulo="handleUpdateModulo" @delete-modulo="handleDeleteModulo" @reorder-modules="handleReorder"
        @refrescar="fetchModuls"></ModuloLeccion>
      <template v-if="leccionActual">
        <LeccionContent :curso="curso" :moduls="moduls" :moduloIndex="moduloIndex" :leccionIndex="leccionIndex"
          :selectedOption="selectedOption" :leccionActual="leccionActual" @update-leccion="handleUpdateLeccion"
          @delete-leccion="handleDeleteLeccion" @reorder-lecciones="handleReorderL">
        </LeccionContent>
        <CuestionarioCurso :navbarra="false" :IDcuestionario="leccionActual.cuestionario" :IDleccion="leccionActual.id"
          :selectedOption="selectedOption" :selectedOptionVista="selectedOptionVista" :cursoId="cursoId"
          @respuesta-enviada="fetchModuls(false)" @nuevo-cuestionario="handleNuevoCuestionario"
          @eliminar-cuestionario="handleEliminarCuestionario">
        </CuestionarioCurso>
      </template>

      <v-col v-if="moduloActual || leccionActual" class="d-flex justify-center align-end" cols="12">
        <v-card class="mb-4">
          <v-card-actions>
            <v-btn prepend-icon="mdi-chevron-left" @click="selectPreviousLeccion" :disabled="!hasPreviousLeccion">
              <span class="d-none d-sm-inline">{{ t('anterior') }}</span>
            </v-btn>
            <v-btn icon v-if="!drawer" color="primary" @click.stop="drawer = true">
              <v-icon icon="mdi-dots-horizontal"></v-icon>
            </v-btn>
            <v-btn append-icon="mdi-chevron-right" @click="selectNextLeccion" :disabled="!hasNextLeccion">
              <span class="d-none d-sm-inline">{{ t('siguiente') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" fullscreen persistent>
      <v-card>
        <HelperCarregant v-if="loading2" :missatge="t('Actualizando') + ' ' + t('actividad')" />
        <template v-if="!loading2">
          <v-toolbar flat>
            <v-toolbar-title>{{ t('Características_de_la_actividad') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="updateCurso(false)" prepend-icon="mdi-content-save">
              {{ t('Guardar') }}
            </v-btn>
            <v-btn icon @click="showDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel :title="t('Características_generales')">
                  <v-expansion-panel-text>
                    <v-text-field v-model="cursoData.titulo" :label="t('Título')" :disabled="dialogType === 'delete'"
                      required></v-text-field>
                    <v-text-field v-model="curso.nombre_director" :label="t('Director')" readonly
                      append-icon="mdi-information" @click:append="() => openHelpDialog('director')"></v-text-field>
                    <v-textarea v-model="cursoData.descripcion" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('descripcion')" :label="t('Descripción')"
                      :disabled="dialogType === 'delete'" required></v-textarea>
                    <v-textarea v-model="cursoData.presentacion" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('presentacion')" :label="t('Presentación')"
                      :disabled="dialogType === 'delete'" required></v-textarea>
                    <v-file-input v-model="cursoData.imagen" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('imagen')" :label="t('Imagen')" prepend-icon="mdi-camera"
                      accept="image/*" @change="handleFileUpload"></v-file-input>
                    <!-- Verifica si hay una URL de imagen y muestra el thumbnail -->
                    <v-img :src="cursoData.imagen_thub" height="100" />
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title>
                    <template v-slot:default>
                      <v-row no-gutters>
                        <v-col class="d-flex justify-start" cols="4">
                          {{ t('Acceso') }}
                        </v-col>
                        <v-col class="text-grey" cols="8">
                          {{ cursoData.estado_curso }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-autocomplete multiple v-model="cursoData.profesionales" :items="tiposProfesionales"
                      item-title="categoria" item-value="id" :label="t('Profesional')" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('profesional')"></v-autocomplete>
                    <v-autocomplete multiple v-model="cursoData.docentes" :items="posibles_docentes" item-title="email"
                      item-value="id" :label="t('Docentes')" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('docentes')"></v-autocomplete>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel :title="t('Acceso_regulado_por_email') + ' (' + t('alumnos') + ')'">
                  <v-expansion-panel-text>
                    <v-textarea v-model="emailsRaw"
                      label="Pega los emails de las personas a las que quieras conceder acceso (@vallhebron.cat)"
                      hint="Puedes copiar y pegar los emails seleccionándolos todos a la vez en una columna de Excel"
                      persistent-hint auto-grow :rows="3"></v-textarea>
                    <v-btn @click="updateCurso(true)" prepend-icon="mdi-content-save">
                      {{ t('Guardar') }} {{ t('emails') }}
                    </v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel :title="t('Características_avanzadas_opcionales')">
                  <v-expansion-panel-text>
                    <v-select multiple v-model="cursoData.rama" :items="tiposRamas" item-title="categoria"
                      item-value="id" :label="t('Áreas')" required append-icon="mdi-information"
                      @click:append="() => openHelpDialog('areas')"></v-select>
                    <v-text-field v-model="cursoData.fecha_inicio" :label="t('Fecha_de_inicio')"
                      type="date"></v-text-field>
                    <v-text-field v-model="cursoData.fecha_fin" :label="t('Fecha_de_fin')" type="date"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_minima" :label="t('Experiencia mínima')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_maxima" :label="t('Experiencia máxima')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.experiencia_docente" :label="t('Experiencia docente')"
                      type="number"></v-text-field>
                    <v-text-field v-model="cursoData.afos" label="AFOS" append-icon="mdi-information"
                      @click:append="() => openHelpDialog('afos')"></v-text-field>
                    <v-btn color="red darken-1" text @click="openDeleteConfirmDialog()">{{ t('Eliminar') }}</v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteConfirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ t('Estás_seguro') }}</v-card-title>
        <v-card-text>
          {{ t('Estás_seguro_que_quieres_eliminar') }} {{ t('la_actividad') }} "{{ curso.titulo }}"? {{
            t('Esta_acción_eliminará_todas') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDeleteConfirmDialog = false">{{ t('Cancelar') }}</v-btn>
          <v-btn color="red darken-1" text @click="confirmDeleteCurso">{{ t('Eliminar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showAddDialog" persistent>
      <v-card>
        <HelperCarregant v-if="loading2" :missatge="t('Creando') + ' ' + t('contenido')" />
        <template v-if="!loading2">
          <v-toolbar flat>
            <v-toolbar-title>{{ t('Crear') }} {{ t(AddForm.tipus) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="SubmitNew()" prepend-icon="mdi-content-save">
              {{ t('Guardar') }}
            </v-btn>
            <v-btn icon @click="showAddDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="AddForm.titulo" :label="t('Título')" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="AddForm.descripcion" :label="t('Descripción')" outlined></v-textarea>
                </v-col>
              </v-row>
              <v-row>Els vídeos, imatges, PDFs i qüestionaris van vinculats a les lliçons. Si vols afegir-ne, ho podràs
                fer en
                un segon temps, editant les lliçons ja creades.</v-row>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="helpDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">{{ t('Ayuda') }}</v-card-title>
        <v-card-text>{{ helpText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="helpDialog = false">{{ t('Cerrar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { axios } from '../axios';
import CuestionarioCurso from './CuestionarioCurso.vue';
import ModuloLeccion from './ModuloLeccion.vue';
import LeccionContent from './LeccionContent.vue';
import HelperCarregant from './HelperCarregant.vue';

const { t } = useI18n();
const display = useDisplay();
const drawer = ref(true);
const store = useStore();
const route = useRoute();
const selectedOptionVista = ref(store.getters.selectedOptionVista);
const curso = ref({});
const moduls = ref({});
const cursoData = ref({});
const tiposProfesionales = ref([]);
const tiposRamas = ref([]);
const emailsRaw = ref('');
const panel = ref([0, 1]);
const leccionActual = ref(null);
const moduloActual = ref(null);
const cursoId = ref(route.params.uid);
const moduloIndex = ref(null);
const leccionIndex = ref(null);
const selectedOption = ref('mirar');
const options = [
  { label: 'Mirar', value: 'mirar', icon: 'mdi-eye' },
  { label: 'Editar', value: 'editar', icon: 'mdi-pencil' }
];
const showDialog = ref(false);
const showAddDialog = ref(false);
const showDeleteConfirmDialog = ref(false);
const AddForm = ref({
  curso: route.params.uid,
  modulo: null,
  tipus: 'modulos',
  tipo: null,
  titulo: '',
  descripcion: '',
});
const snackbarText = ref('');
const posibles_docentes = ref([]);
const helpDialog = ref(false);
const helpText = ref('');
const loading = ref(true);
const loadingm = ref(true);
const loading2 = ref(false);

const hasNextLeccion = computed(() => {
  const isLastLeccionInModulo = leccionIndex.value === moduls.value.modulos[moduloIndex.value].lecciones.length - 1;
  const isLastModulo = moduloIndex.value === moduls.value.modulos.length - 1;
  return !isLastLeccionInModulo || !isLastModulo;
});

const hasPreviousLeccion = computed(() => {
  const isFirstLeccionInModulo = leccionIndex.value === -1;
  const isFirstModulo = moduloIndex.value === 0;
  return !isFirstLeccionInModulo || !isFirstModulo;
});

watch(emailsRaw, (newVal) => {
  processPastedEmails(newVal);
});

watch(selectedOption, (newVal, oldVal) => {
  if (oldVal === 'editar' && newVal === 'mirar') {
    fetchCurso();
  }
});

const openHelpDialog = (field) => {
  helpDialog.value = true;
  switch (field) {
    case 'descripcion':
      helpText.value = 'Describe tu actividad con una frase atractiva.';
      break;
    case 'presentacion':
      helpText.value = 'Haz una presentación o introducción a tu actividad.';
      break;
    case 'imagen':
      helpText.value = 'Sube una imagen representativa de la actividad.';
      break;
    case 'profesional':
      helpText.value = '¿A qué profesionales va dirigida tu actividad? Marca todos los que apliquen.';
      break;
    case 'director':
      helpText.value = 'El director es la persona responsable del contenido de la actividad. El director puede editar el contenido de su actividad y su acceso por parte de otros usuarios.';
      break;
    case 'docentes':
      helpText.value = 'Puedes dar acceso docente a otros profesionales registrados que estén relacionados con la especialidad o categoría profesional de tu actividad. Los docentes tienen acceso a la guía docente de la actividad y pueden evaluar los alumnos.';
      break;
    case 'areas':
      helpText.value = '¿En qué URLs tiene que estar disponible tu actividad? Por defecto selecciona vallhebron (vallhebron.simulacio.com). Si deseas que tu actividad esté disponible en alguna de las otras URLs (por ejemplo gine.simulacio.com), seleccionalas.';
      break;
    case 'afos':
      helpText.value = 'El código AFOS sirve para identificar tu actividad en el Centre de Simulació. Para que el control de asistencia con QR funcione correctamente es fundamental que el código coincida exactamente con el código introducido en el sistema RedCap del centro.';
      break;
    default:
      helpText.value = 'Información no disponible.';
  }
};

const selectModulo = (modulo, moduloIndexValue) => {
  moduloActual.value = modulo;
  leccionActual.value = null;
  moduloIndex.value = moduloIndexValue;
  leccionIndex.value = -1;
};

const selectLeccion = (leccion, moduloIndexValue, leccionIndexValue) => {
  moduloActual.value = null;
  leccionActual.value = leccion;
  moduloIndex.value = moduloIndexValue;
  leccionIndex.value = leccionIndexValue;
};

const selectNextLeccion = () => {
  if (leccionIndex.value < moduls.value.modulos[moduloIndex.value].lecciones.length - 1) {
    leccionIndex.value++;
    moduloActual.value = null;
  } else if (moduloIndex.value < moduls.value.modulos.length - 1) {
    moduloIndex.value++;
    leccionIndex.value = -1;
    moduloActual.value = moduls.value.modulos[moduloIndex.value];
  } else {
    return;
  }
  leccionActual.value = moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value];
};

const selectPreviousLeccion = () => {
  if (leccionIndex.value > 0) {
    leccionIndex.value--;
    moduloActual.value = null;
  } else if (leccionIndex.value == 0) {
    leccionIndex.value--;
    moduloActual.value = moduls.value.modulos[moduloIndex.value];
  } else if (moduloIndex.value > 0) {
    moduloIndex.value--;
    moduloActual.value = moduls.value.modulos[moduloIndex.value];
    leccionIndex.value = moduls.value.modulos[moduloIndex.value].lecciones.length - 1;
  } else {
    moduloActual.value = moduls.value.modulos[moduloIndex.value];
    leccionIndex.value = -1;
  }
  leccionActual.value = moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value];
};

const fetchProfesionales = async () => {
  try {
    const response = await axios.get('profesionales/');
    tiposProfesionales.value = response.data.sort((a, b) => a.categoria.localeCompare(b.categoria));
  } catch (error) {
    console.error(error);
  }
};

const fetchRamas = async () => {
  try {
    const response = await axios.get('ramas/');
    tiposRamas.value = response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchCurso = async () => {
  loading.value = true;
  try {
    const response = await axios.get(`curso/${cursoId.value}/`);
    curso.value = response.data;
    loading.value = false;
  } catch (error) {
    if (error.response && error.response.data) {
      snackbarText.value = error.response.data.message;
    }
    snackbarText.value = error;
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    loading.value = false;
  }
};

const fetchModuls = async (isLoading) => {
  loadingm.value = isLoading;
  selectedOptionVista.value = store.getters.selectedOptionVista;
  try {
    const response = await axios.get(`curso_i/${cursoId.value}/${selectedOptionVista.value}/`);
    moduls.value = response.data;
    loadingm.value = false;
  } catch (error) {
    if (error.response && error.response.data) {
      snackbarText.value = error.response.data.message;
    }
    snackbarText.value = error;
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    loadingm.value = false;
  }
};

const openEditDialog = () => {
  getCursoData();
  fetchProfesionales();
  fetchPosiblesDocentes();
  fetchRamas();
  showDialog.value = true;
};

const getCursoData = () => {
  axios.get(`cursos/${curso.value.id}/`)
    .then(response => {
      cursoData.value = response.data;
      cursoData.value.imagen_thub = response.data.imagen;
      cursoData.value.imagen = null;
      emailsRaw.value = cursoData.value.admitidos.join('\n');
    })
    .catch(error => {
      console.error(error);
    });
};

const processPastedEmails = (emailsString) => {
  const emails = emailsString.split(/\r?\n/);
  cursoData.value.admitidos = emails.map(email => email.trim()).filter(email => email);
};

const handleFileUpload = (event) => {
  if (event.target.files && event.target.files.length > 0) {
    cursoData.value.imagen = event.target.files[0];
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cursoData.value.imagen_thub = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  } else {
    cursoData.value.imagen = null;
  }
};

const updateCurso = (emails) => {
  loading2.value = true;
  if (cursoData.value.rama.length === 0) {
    snackbarText.value = 'Debes seleccionar al menos una área en la sección de visibilidad';
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    return;
  }
  const formData = new FormData();
  Object.keys(cursoData.value).forEach(key => {
    const value = cursoData.value[key];
    if (value !== null && value !== undefined) {
      if (key === 'imagen' && value instanceof File) {
        formData.append(key, value);
      } else if (key === 'admitidos' && typeof value === 'object') {
        if (emails === true) {
          formData.append(key, JSON.stringify(value));
        }
      } else if (Array.isArray(value)) {
        value.forEach(item => {
          formData.append(key, item);
        });
      } else if (key !== 'imagen_thub') { // Solo omitir imagen_thub
        formData.append(key, value);
      }
    }
  });

  axios.put(`cursos/${curso.value.id}/`, formData)
    .then(response => {
      curso.value = response.data;
      showDialog.value = false;
      loading2.value = false;
      snackbarText.value = response.data.titulo + ' ' + 'fue actualizado con éxito';
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
      fetchCurso();
    })
    .catch(error => {
      if (error.response) {
        if (error.response.data.non_field_errors) {
          snackbarText.value = 'Error: ' + error.response.data.non_field_errors.join(', ');
        } else if (error.response.data.detail) {
          snackbarText.value = 'Error: ' + error.response.data.detail;
        } else {
          snackbarText.value = '';
          Object.keys(error.response.data).forEach(key => {
            snackbarText.value += `${key}: ${error.response.data[key].join(', ')}; `;
          });
        }
      }
      loading2.value = false;
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
    });
};

const openDeleteConfirmDialog = () => {
  showDeleteConfirmDialog.value = true;
};

const confirmDeleteCurso = () => {
  loading2.value = true;
  axios.delete(`cursos/${curso.value.id}`)
    .then(() => {
      showDialog.value = false;
      showDeleteConfirmDialog.value = false;
      this.$router.push('/inspira');
      snackbarText.value = t("se_ha_eliminado_con_éxito");
      store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
      loading2.value = false;
    })
    .catch(error => {
      store.dispatch('triggerSnackbar', { message: error, color: 'error' });
      loading2.value = false;
    });
};

const openAddDialog = (tipus, moduloId = null) => {
  AddForm.value.tipus = tipus;
  AddForm.value.titulo = '';
  AddForm.value.descripcion = '';
  if (tipus === 'lecciones') {
    AddForm.value.modulo = moduloId;
  } else {
    AddForm.value.modulo = null;
    AddForm.value.tipo = selectedOptionVista.value;
  }
  showAddDialog.value = true;
};

const SubmitNew = async () => {
  loading2.value = true;
  const formData = new FormData();
  Object.keys(AddForm.value).forEach(key => {
    const value = AddForm.value[key];
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });
  await axios.post(`${AddForm.value.tipus}/`, formData).then(response => {
    snackbarText.value = response.data.titulo + ' ' + 'fue creado con éxito';
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'success' });
    showAddDialog.value = false;
    loading2.value = false;
    fetchModuls(true);
  }).catch(error => {
    if (error.response) {
      if (error.response.data.non_field_errors) {
        snackbarText.value = 'Error: ' + error.response.data.non_field_errors.join(', ');
      } else if (error.response && error.response.data && typeof error.response.data === 'object') {
        const errors = error.response.data;
        let errorMessage = '';
        Object.keys(errors).forEach(key => {
          errorMessage += `${key}: ${errors[key].join(' ')}\n`;
        });
        snackbarText.value = errorMessage;
      }
    }
    loading2.value = false;
    snackbarText.value = error;
    store.dispatch('triggerSnackbar', { message: snackbarText.value, color: 'error' });
  });
};

const handleNuevoCuestionario = ({ data }) => {
  moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value].cuestionario = data;
  leccionActual.value.cuestionario = data;
};

const handleEliminarCuestionario = () => {
  moduls.value.modulos[moduloIndex.value].lecciones[leccionIndex.value].cuestionario = null;
  leccionActual.value.cuestionario = null;
};

const handleUpdateModulo = ({ index, data }) => {
  moduls.value.modulos.splice(index, 1, data);
};

const handleDeleteModulo = (index) => {
  moduloActual.value = null;
  moduls.value.modulos.splice(index, 1);
};

const handleUpdateLeccion = ({ index, data }) => {
  moduls.value.modulos[moduloIndex.value].lecciones.splice(index, 1, data);
};

const handleDeleteLeccion = (index) => {
  leccionActual.value = null;
  moduls.value.modulos[moduloIndex.value].lecciones.splice(index, 1);
};

const handleReorder = (payload) => {
  const { type, index } = payload;
  if (type === 'up' && index > 0) {
    const temp = moduls.value.modulos[index];
    moduls.value.modulos[index] = moduls.value.modulos[index - 1];
    moduls.value.modulos[index - 1] = temp;
  } else if (type === 'down' && index < moduls.value.modulos.length - 1) {
    const temp = moduls.value.modulos[index];
    moduls.value.modulos[index] = moduls.value.modulos[index + 1];
    moduls.value.modulos[index + 1] = temp;
  }
};

const handleReorderL = (payload) => {
  const { type, index } = payload;
  if (type === 'up' && index > 0) {
    const temp = moduls.value.modulos[moduloIndex.value].lecciones[index];
    moduls.value.modulos[moduloIndex.value].lecciones[index] = moduls.value.modulos[moduloIndex.value].lecciones[index - 1];
    moduls.value.modulos[moduloIndex.value].lecciones[index - 1] = temp;
  } else if (type === 'down' && index < moduls.value.modulos[moduloIndex.value].lecciones.length - 1) {
    const temp = moduls.value.modulos[moduloIndex.value].lecciones[index];
    moduls.value.modulos[moduloIndex.value].lecciones[index] = moduls.value.modulos[moduloIndex.value].lecciones[index + 1];
    moduls.value.modulos[moduloIndex.value].lecciones[index + 1] = temp;
  }
};

const fetchPosiblesDocentes = async () => {
  try {
    const response = await axios.get(`curso/${cursoId.value}/posibles_docentes/`);
    posibles_docentes.value = response.data;
  } catch (error) {
    console.error("Error al obtener los alumnos pendientes:", error);
  }
};

onMounted(() => {
  fetchCurso();
  fetchModuls(true);
});
</script>