<template>
  <v-app>
    <NavBar :branca="branca" :logoImage="logoImage" v-if="isLoggedIn && !shouldShowPlantilles" />
    <NavBarPlantilles v-if="shouldShowPlantilles" />
    <v-main>
      <template v-if="!base">
        <v-parallax v-if="!isLoggedIn" :src=backgroundImage class="fill-height">
          <router-view fluid class="pa-0 fill-height" :isLoggedIn="isLoggedIn" ref="routerView" />
        </v-parallax>
        <router-view v-if="isLoggedIn" fluid class="pa-0 fill-height" :isLoggedIn="isLoggedIn" ref="routerView" />
      </template>
      <template v-if="base">
        <v-col cols="12" md="4" v-for="(branca, i) in branques" :key="i">
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  {{ branca.branca }}SIM
                </v-card-title>
                <v-card-subtitle>Hospital Vall d'Hebron</v-card-subtitle>
                <v-card-actions>
                  <v-btn target="_blank" :href="branca.link" class="ms-2" size="small" text="Accedeix" variant="outlined"></v-btn>
                </v-card-actions>
              </div>
              <v-avatar class="ma-3" rounded="0" size="125">
                <v-img :src="`@/assets/avatar/avatar_${branca.branca}.jpg`"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-main>
    <DescripcioGeneral v-if="!isLoggedIn && !shouldShowPlantilles" :title="theme.current.value.branca"
      :description="description"></DescripcioGeneral>
    <HomeFooter v-if="shouldShowFooter" :brancagine="brancagine" />
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="5000">{{ snackbar.message }}</v-snackbar>
  </v-app>
</template>

<script setup>
import { useStore } from 'vuex';
import { ref, computed, watch, onMounted, watchEffect } from 'vue';
import { useTheme } from 'vuetify';
import { useRoute } from 'vue-router'; // Corrected import source
import DescripcioGeneral from './components/DescripcioGeneral.vue';
import NavBar from './components/NavBar.vue';
import HomeFooter from './components/HomeFooter.vue';
import NavBarPlantilles from './components/NavBarPlantilles.vue';
import { setAuthToken } from './axios';

const theme = useTheme();
const route = useRoute();

const backgroundImage = ref('');
const logoImage = ref('');
const description = ref('');
const description_breve = ref('');
const branca = ref('');
const base = ref(true);
const isLoggedIn = ref(false);
const brancagine = ref('');

const branques = ref([
  { branca: 'gine', link: 'https://gine.simulacio.com' },
  { branca: 'vallhebron', link: 'https://vallhebron.simulacio.com' },
  { branca: 'cardio', link: 'https://cardio.simulacio.com' },

]);

// Access Vuex store
const store = useStore();

// Using computed properties to access Vuex state
const snackbar = computed(() => store.state.snackbar);

// Watching for route changes
watch(route, () => {
  isLoggedIn.value = !!localStorage.getItem('authToken');
});

// Dynamic theme adjustment based on hostname
watchEffect(() => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'gine.simulacio.com':
      theme.global.name.value = 'gineTheme';
      base.value = false;
      break;
    case 'vallhebron.simulacio.com':
      theme.global.name.value = 'vallhebronTheme';
      base.value = false;
      break;
    case 'cardio.simulacio.com':
      theme.global.name.value = 'cardioTheme';
      base.value = false;
      break;
    case 'simulacio.com':
      theme.global.name.value = 'baseTheme';
      base.value = true;
      break;
    default:
      theme.global.name.value = 'vallhebronTheme';
      base.value = true;
      break;
  }
});

// Computed properties for UI logic
const shouldShowFooter = computed(() => {
  return !/^\/curso\/.+/.test(route.path) && !/^\/curso_inspira\/.+/.test(route.path);
});

const shouldShowPlantilles = computed(() => {
  return /^\/plantilles(\/|$)/.test(route.path);
});

// Initial setup
onMounted(() => {
  isLoggedIn.value = !!localStorage.getItem('authToken');
  if (isLoggedIn.value) {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
  }
});

function loadImageAssets() {
  const imageCode = theme.current.value.branca_code;
  document.title = theme.current.value.branca;
  branca.value = imageCode;
  description.value = `${imageCode}_es`;  
  description_breve.value = `Mejora_${imageCode}`;
  importImagesAndLogos(imageCode);
}

function importImagesAndLogos(code) {
  import(`@/assets/fons/fons_${code}.webp`)
    .then(module => {
      backgroundImage.value = module.default;
    })
    .catch(error => {
      console.error("Failed to load background image:", error);
      backgroundImage.value = ''; // Fallback image or handle the error
    });
  import(`@/assets/logos/logo_${code}.png`)
    .then(module => {
      logoImage.value = module.default;
    })
    .catch(error => {
      console.error("Failed to load logo image:", error);
      logoImage.value = ''; // Fallback logo or handle the error
    });
}

// This should be triggered based on changes in theme to load corresponding assets
watch(theme, () => {
  loadImageAssets();
}, { immediate: true });
</script>
