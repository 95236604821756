<template>
  <v-container fluid class="d-flex flex-column pa-3 fill-height">
    <v-row class="d-flex flex-column justify-center align-center" align-self="center">
      <!-- Hero Section -->

      <v-row class="d-flex align-center">
        <!-- Columna Derecha (contenido de GineSIM) -->
        <!-- Usamos 'order-md-1' para asegurarnos de que este contenido se muestre primero en pantallas medianas y mayores -->
        <v-col cols="12" md="6" class="order-md-2 order-1">
          <v-card class="ma-10">
            <v-card-text>
              <h1 class="text-h4 font-weight-thin mb-4">
                {{ theme.current.value.branca }}
              </h1>
              <p>

                {{ t(description_breve) }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="mr-4" @click="$router.push('/login')">
                {{ t('Iniciar_sesión') }}
              </v-btn>
              <v-btn color="secondary" @click="$router.push('/register')">
                {{ t('Registrarse') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- Columna Izquierda (Aprende, Practica, Domina) -->
        <!-- Usamos 'order-md-2' para asegurarnos de que este contenido se muestre segundo en pantallas medianas y mayores -->
        <v-col cols="12" md="6" class="order-md-1 order-2 pa-10">
          <v-row class="d-flex justify-center">
            <v-sheet style="background-color: transparent;" class="d-flex justify-center align-center flex-column"
              @click="$router.push('/login')">

              <!-- Inline styles for larger white text and transparent background -->
              <v-chip variant="elevated" color="primary" append-icon="mdi-telescope" class="d-flex align-center ma-7"
                size="x-large">
                {{ t('Explora') }}
              </v-chip>
              <v-chip variant="elevated" color="primary" append-icon="mdi-school" class="d-flex align-center ma-7"
                size="x-large">
                {{ t('Aprende') }}
              </v-chip>
              <v-chip variant="elevated" color="primary" append-icon="mdi-lightbulb-on-outline"
                class="d-flex align-center ma-7" size="x-large">
                {{ t('Inspira') }}
              </v-chip>
            </v-sheet>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>


<script setup>
import { useTheme } from 'vuetify';
import { ref, watchEffect } from 'vue';

const theme = useTheme();

const backgroundImage = ref('');
const description = ref('');
const description_breve = ref('');

// Observa cambios en el código de tema actual y carga la imagen correspondiente
watchEffect(() => {
  const imageCode = theme.current.value.branca_code; // Asegúrate de que esto refleje cómo se identifica el tema
  description.value = `${imageCode}_es`
  description_breve.value = `Mejora_${imageCode}`
  import(`@/assets/fons/fons_${imageCode}.webp`)
    .then(module => {
      backgroundImage.value = module.default;
    })
    .catch(error => {
      console.error("Failed to load image:", error);
      backgroundImage.value = ''; // Provee una imagen de respaldo o maneja el error adecuadamente
    });
});

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

</script>
